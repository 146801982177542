import React, { FC, useContext, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { PencilAltOutline, UserAddOutline } from "heroicons-react";
import { UsersResponse, User } from "../lib/models";
import { AppContext } from "../context/store";
import { Constants } from "../constants";
import { SearchBox } from "./table/searchBox";
import { ActionType } from "../context/reducer";
import Modal, { ModelConfirmation } from "./Modal";
import { useSearchParams } from "react-router-dom";
import { isV2Api } from "../lib/helper";
import { USE_NEW_BACKEND_KEY } from "../constants";
export interface UserTableProps {
  rows: UsersResponse;
  searchCallback?: (search: string) => void;
  addEditUserCallback: (username: string) => void;
  userExport?: () => void;
}

const UserTableComp: FC<UserTableProps> = ({ rows, searchCallback, addEditUserCallback, userExport }): JSX.Element => {
  const { state, dispatch } = useContext(AppContext);
  const searchRef = useRef<HTMLInputElement>(null);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const isNewBackend = isV2Api(searchParams.get(USE_NEW_BACKEND_KEY));

  const handleOnAddEditUser = (userRow: User) => {
    dispatch({ type: ActionType.SET_USER, payload: userRow });
    addEditUserCallback(userRow.username);
  };

  // Edit button in datatable
  const editButton = (row: User): JSX.Element => {
    return (
      <button
        type="button"
        className="inline-flex items-center btn btn-clear mr-2"
        onClick={(): void => handleOnAddEditUser(row)}
      >
        <PencilAltOutline size={18} className="mr-2" /> Edit
      </button>
    );
  };

  const isAdmin = (): boolean => {
    return state.userRole === Constants.ADMIN;
  };

  const createActions = (row: User): JSX.Element => {
    return <>{isAdmin() && editButton(row)}</>;
  };

  const handleKeyUp = (e: { key: string }) => {
    if (
      (e.key === "Enter" && searchRef.current.value && searchRef.current.value.length > 2) ||
      searchRef.current.value.length === 0
    ) {
      searchCallback(searchRef.current.value);
    }
  };

  const handleOnBlur = () => {
    if (searchRef.current.value.length > 2 || searchRef.current.value.length === 0) {
      searchCallback(searchRef.current.value);
    }
  };

  const onReset = () => {
    searchRef.current.value = "";
    searchCallback("");
  };

  const exportDataConfirm = (confirmation: string) => {
    setExportModalOpen(false);
    if (confirmation === ModelConfirmation.Yes) {
      userExport();
    }
  };

  const subHeaderComponentMemo = React.useMemo((): JSX.Element => {
    return (
      <div className="flex w-full py-4">
        <div className="flex-1 min-w-0">
          {isAdmin() && (
            <div className="inline-flex">
              <button
                type="button"
                data-cy="add-modal-button"
                className="inline-flex items-center btn btn-clear"
                onClick={(): void => handleOnAddEditUser({} as User)}
              >
                <UserAddOutline size={18} className="mr-2" /> Add User
              </button>
              {isNewBackend && (
                <button
                  type="button"
                  data-cy="export-modal-button"
                  className="inline-flex items-center btn btn-clear ml-4"
                  onClick={() => setExportModalOpen(true)}
                >
                  Export CSV
                </button>
              )}
            </div>
          )}
        </div>
        <div className="inline-flex items-end w-1/4">
          <SearchBox ref={searchRef} onKeyUp={handleKeyUp} handleOnBlur={handleOnBlur} onReset={onReset} />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userRole]);

  // Creates datatable columns, includes formatting of date and sortable bool
  const newCols = React.useMemo(
    () => [
      {
        name: "Username",
        selector: "username",
        sortable: true,
        maxWidth: "300px",
      },
      {
        name: "Email ID",
        selector: "email",
        sortable: true,
        maxWidth: "300px",
      },
      {
        name: "Status",
        selector: "user_status",
        sortable: true,
        maxWidth: "300px",
      },
      {
        name: "Last Modified on",
        selector: "modified_at",
        cell: (row: User) => {
          return row.modified_at && row.modified_at !== "0001-01-01T00:00:00Z"
            ? moment(row.modified_at).format("MMM DD, YYYY HH:mm:ss")
            : "N/A";
        },
        sortable: true,
        maxWidth: "300px",
      },
      {
        name: "Role",
        selector: "role",
        sortable: true,
        maxWidth: "150px",
      },
      {
        name: "Actions",
        button: true,
        cell: (row: User) => createActions(row),
        right: true,
        minWidth: "150px",
        omit: !isAdmin(),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.userRole]
  );

  return (
    <div data-testid="dataTable">
      <DataTable
        className="data-table"
        columns={newCols}
        data={rows.users}
        pagination
        highlightOnHover
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        persistTableHead
        defaultSortFieldId="username"
        defaultSortAsc
      />

      {isExportModalOpen && (
        <Modal
          isShowing={isExportModalOpen}
          hide={null}
          confirmation={exportDataConfirm}
          modalBody={<div>Do you want to save users CSV file on your machine?</div>}
        />
      )}
    </div>
  );
};

export default UserTableComp;
