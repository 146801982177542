/* tslint:disable */
/* eslint-disable */
/**
 * Device Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.7.0
 * Contact: tbc@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface CreateUserParams
 */
export interface CreateUserParams {
  /**
   * Username for the new user
   * @type {string}
   * @memberof CreateUserParams
   */
  username: string;
  /**
   * User role
   * @type {string}
   * @memberof CreateUserParams
   */
  role: CreateUserParamsRoleEnum;
}

export const CreateUserParamsRoleEnum = {
  Admin: "admin",
  User: "user",
} as const;

export type CreateUserParamsRoleEnum = (typeof CreateUserParamsRoleEnum)[keyof typeof CreateUserParamsRoleEnum];

/**
 *
 * @export
 * @interface Device
 */
export interface Device {
  /**
   * Device ID
   * @type {string}
   * @memberof Device
   */
  id: string;
  /**
   * Device Name
   * @type {string}
   * @memberof Device
   */
  name: string;
  /**
   * Device Type
   * @type {string}
   * @memberof Device
   */
  type: DeviceTypeEnum;
  /**
   * Device Node Id
   * @type {string}
   * @memberof Device
   */
  node_id: string;
  /**
   * Branch Id
   * @type {string}
   * @memberof Device
   */
  branch_id: string;
  /**
   * Branch Name
   * @type {string}
   * @memberof Device
   */
  branch_name: string;
  /**
   * Branch Address
   * @type {string}
   * @memberof Device
   */
  branch_address: string;
  /**
   * Branch Postcode
   * @type {string}
   * @memberof Device
   */
  branch_postcode: string;
  /**
   * Branch Organisation Unit Code
   * @type {string}
   * @memberof Device
   */
  branch_org_unit_code: string;
  /**
   * Branch Organisation Unit Code Version
   * @type {string}
   * @memberof Device
   */
  branch_org_unit_code_version: string;
  /**
   * Device notes
   * @type {string}
   * @memberof Device
   */
  notes: string;
  /**
   * Record Creation Date Time
   * @type {string}
   * @memberof Device
   */
  created_at: string;
  /**
   * Record Creation By
   * @type {string}
   * @memberof Device
   */
  created_by: string;
  /**
   * Record Modified Date Time
   * @type {string}
   * @memberof Device
   */
  modified_at: string;
  /**
   * Record Modified By
   * @type {string}
   * @memberof Device
   */
  modified_by: string;
  /**
   * Last OTP Generated Date Time
   * @type {string}
   * @memberof Device
   */
  last_otp_at: string;
  /**
   * Last OTP Generated By
   * @type {string}
   * @memberof Device
   */
  last_otp_by: string;
  /**
   * Activation State
   * @type {string}
   * @memberof Device
   */
  state?: DeviceStateEnum;
  /**
   * Deactivation code
   * @type {string}
   * @memberof Device
   */
  deactivation_code?: DeviceDeactivationCodeEnum;
}

export const DeviceTypeEnum = {
  Payzone: "payzone",
  Counter: "counter",
  Mobile: "mobile",
} as const;

export type DeviceTypeEnum = (typeof DeviceTypeEnum)[keyof typeof DeviceTypeEnum];
export const DeviceStateEnum = {
  Inactive: "inactive",
  Active: "active",
} as const;

export type DeviceStateEnum = (typeof DeviceStateEnum)[keyof typeof DeviceStateEnum];
export const DeviceDeactivationCodeEnum = {
  FaultyDevice: "faulty_device",
  BranchClosed: "branch_closed",
  DeviceNoLongerInUse: "device_no_longer_in_use",
} as const;

export type DeviceDeactivationCodeEnum = (typeof DeviceDeactivationCodeEnum)[keyof typeof DeviceDeactivationCodeEnum];

/**
 *
 * @export
 * @interface Devices
 */
export interface Devices {
  /**
   *
   * @type {number}
   * @memberof Devices
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof Devices
   */
  items: number;
  /**
   *
   * @type {string}
   * @memberof Devices
   */
  offset: string;
  /**
   *
   * @type {Array<Device>}
   * @memberof Devices
   */
  devices: Array<Device>;
}
/**
 * To track what has been updated by whom and when
 * @export
 * @interface Event
 */
export interface Event {
  /**
   * Event ID
   * @type {string}
   * @memberof Event
   */
  id: string;
  /**
   * Entity ID
   * @type {string}
   * @memberof Event
   */
  entity_id: string;
  /**
   * Name of the entity on which action was performed
   * @type {string}
   * @memberof Event
   */
  event_entity: string;
  /**
   * Name of the event
   * @type {string}
   * @memberof Event
   */
  event_name: string;
  /**
   * custom message for the event
   * @type {string}
   * @memberof Event
   */
  event_message?: string;
  /**
   * previous state of data (if any)
   * @type {any}
   * @memberof Event
   */
  previous_state?: any;
  /**
   * new state of data
   * @type {any}
   * @memberof Event
   */
  new_state?: any;
  /**
   * event created by
   * @type {string}
   * @memberof Event
   */
  created_by: string;
  /**
   * event created at
   * @type {string}
   * @memberof Event
   */
  created_at?: string;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 *
 * @export
 * @interface Otp
 */
export interface Otp {
  /**
   *
   * @type {string}
   * @memberof Otp
   */
  otp?: string;
}
/**
 *
 * @export
 * @interface PatchDeviceRequest
 */
export interface PatchDeviceRequest {
  /**
   *
   * @type {string}
   * @memberof PatchDeviceRequest
   */
  state: PatchDeviceRequestStateEnum;
  /**
   *
   * @type {string}
   * @memberof PatchDeviceRequest
   */
  deactivation_code?: PatchDeviceRequestDeactivationCodeEnum;
}

export const PatchDeviceRequestStateEnum = {
  Inactive: "inactive",
  Active: "active",
} as const;

export type PatchDeviceRequestStateEnum =
  (typeof PatchDeviceRequestStateEnum)[keyof typeof PatchDeviceRequestStateEnum];
export const PatchDeviceRequestDeactivationCodeEnum = {
  FaultyDevice: "faulty_device",
  BranchClosed: "branch_closed",
  DeviceNoLongerInUse: "device_no_longer_in_use",
} as const;

export type PatchDeviceRequestDeactivationCodeEnum =
  (typeof PatchDeviceRequestDeactivationCodeEnum)[keyof typeof PatchDeviceRequestDeactivationCodeEnum];

/**
 *
 * @export
 * @interface SaveDeviceParams
 */
export interface SaveDeviceParams {
  /**
   * Branch Id
   * @type {string}
   * @memberof SaveDeviceParams
   */
  id: string;
  /**
   * Device Name
   * @type {string}
   * @memberof SaveDeviceParams
   */
  name: string;
  /**
   * Device Type
   * @type {string}
   * @memberof SaveDeviceParams
   */
  type: SaveDeviceParamsTypeEnum;
  /**
   * Node Id
   * @type {string}
   * @memberof SaveDeviceParams
   */
  node_id: string;
  /**
   * Branch ID
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_id: string;
  /**
   * Branch Name
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_name: string;
  /**
   * Branch Address
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_address: string;
  /**
   * Branch Postcode
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_postcode: string;
  /**
   * Branch Org Unit Code
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_org_unit_code: string;
  /**
   * Branch Org Unit Code Version
   * @type {string}
   * @memberof SaveDeviceParams
   */
  branch_org_unit_code_version: string;
  /**
   * Device notes (optional)
   * @type {string}
   * @memberof SaveDeviceParams
   */
  notes: string;
}

export const SaveDeviceParamsTypeEnum = {
  Payzone: "payzone",
  Counter: "counter",
  Mobile: "mobile",
} as const;

export type SaveDeviceParamsTypeEnum = (typeof SaveDeviceParamsTypeEnum)[keyof typeof SaveDeviceParamsTypeEnum];

/**
 *
 * @export
 * @interface UpdateDeviceOTPRequestParams
 */
export interface UpdateDeviceOTPRequestParams {
  /**
   *
   * @type {string}
   * @memberof UpdateDeviceOTPRequestParams
   */
  id: string;
}
/**
 *
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
  /**
   * User role
   * @type {string}
   * @memberof UpdateUserParams
   */
  role?: UpdateUserParamsRoleEnum;
}

export const UpdateUserParamsRoleEnum = {
  Admin: "admin",
  User: "user",
} as const;

export type UpdateUserParamsRoleEnum = (typeof UpdateUserParamsRoleEnum)[keyof typeof UpdateUserParamsRoleEnum];

/**
 * User detail fetched successfully
 * @export
 * @interface User
 */
export interface User {
  /**
   * Registered Email ID
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   * Username
   * @type {string}
   * @memberof User
   */
  username: string;
  /**
   * User role
   * @type {string}
   * @memberof User
   */
  role: UserRoleEnum;
  /**
   * User is enabled or not
   * @type {boolean}
   * @memberof User
   */
  enabled: boolean;
  /**
   * User is confirmed or pending
   * @type {string}
   * @memberof User
   */
  user_status: string;
  /**
   * Record Updated Date Time
   * @type {string}
   * @memberof User
   */
  modified_at: string;
  /**
   * Record Creation Date Time
   * @type {string}
   * @memberof User
   */
  created_at: string;
}

export const UserRoleEnum = {
  Admin: "admin",
  User: "user",
} as const;

export type UserRoleEnum = (typeof UserRoleEnum)[keyof typeof UserRoleEnum];

/**
 *
 * @export
 * @interface Users
 */
export interface Users {
  /**
   *
   * @type {number}
   * @memberof Users
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof Users
   */
  offset?: string;
  /**
   *
   * @type {Array<User>}
   * @memberof Users
   */
  users?: Array<User>;
}

/**
 * BuilderDeviceManagementApi - axios parameter creator
 * @export
 */
export const BuilderDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1BuilderPutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v1BuilderPutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/builder/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2BuilderPutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v2BuilderPutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/v2/builder/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BuilderDeviceManagementApi - functional programming interface
 * @export
 */
export const BuilderDeviceManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BuilderDeviceManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1BuilderPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1BuilderPutDeviceOtp(
        updateDeviceOTPRequestParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2BuilderPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2BuilderPutDeviceOtp(
        updateDeviceOTPRequestParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BuilderDeviceManagementApi - factory interface
 * @export
 */
export const BuilderDeviceManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BuilderDeviceManagementApiFp(configuration);
  return {
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1BuilderPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: any
    ): AxiosPromise<Otp> {
      return localVarFp
        .v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2BuilderPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: any
    ): AxiosPromise<Otp> {
      return localVarFp
        .v2BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BuilderDeviceManagementApi - object-oriented interface
 * @export
 * @class BuilderDeviceManagementApi
 * @extends {BaseAPI}
 */
export class BuilderDeviceManagementApi extends BaseAPI {
  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof BuilderDeviceManagementApi
   */
  public v1BuilderPutDeviceOtp(
    updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
    options?: AxiosRequestConfig
  ) {
    return BuilderDeviceManagementApiFp(this.configuration)
      .v1BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BuilderDeviceManagementApi
   */
  public v2BuilderPutDeviceOtp(
    updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
    options?: AxiosRequestConfig
  ) {
    return BuilderDeviceManagementApiFp(this.configuration)
      .v2BuilderPutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DeviceManagementApi - axios parameter creator
 * @export
 */
export const DeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("v1GetDevice", "id", id);
      const localVarPath = `/device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetDevices: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PostDevice: async (
      saveDeviceParams: SaveDeviceParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'saveDeviceParams' is not null or undefined
      assertParamExists("v1PostDevice", "saveDeviceParams", saveDeviceParams);
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutDevice: async (saveDeviceParams: SaveDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'saveDeviceParams' is not null or undefined
      assertParamExists("v1PutDevice", "saveDeviceParams", saveDeviceParams);
      const localVarPath = `/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v1PutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Export users or devices to CSV file
     * @summary Export users or devices
     * @param {'user' | 'device'} entity Export list of all users or devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2Export: async (entity: "user" | "device", options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'entity' is not null or undefined
      assertParamExists("v2Export", "entity", entity);
      const localVarPath = `/v2/export/{entity}`.replace(`{${"entity"}}`, encodeURIComponent(String(entity)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetDevice: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("v2GetDevice", "id", id);
      const localVarPath = `/v2/device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetDevices: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API to update device state (active/inactive)
     * @summary Patch device
     * @param {string} id Device id to patch/update
     * @param {PatchDeviceRequest} patchDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PatchDevice: async (
      id: string,
      patchDeviceRequest: PatchDeviceRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("v2PatchDevice", "id", id);
      // verify required parameter 'patchDeviceRequest' is not null or undefined
      assertParamExists("v2PatchDevice", "patchDeviceRequest", patchDeviceRequest);
      const localVarPath = `/v2/device/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PATCH", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(patchDeviceRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PostDevice: async (
      saveDeviceParams: SaveDeviceParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'saveDeviceParams' is not null or undefined
      assertParamExists("v2PostDevice", "saveDeviceParams", saveDeviceParams);
      const localVarPath = `/v2/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutDevice: async (saveDeviceParams: SaveDeviceParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'saveDeviceParams' is not null or undefined
      assertParamExists("v2PutDevice", "saveDeviceParams", saveDeviceParams);
      const localVarPath = `/v2/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(saveDeviceParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v2PutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/v2/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DeviceManagementApi - functional programming interface
 * @export
 */
export const DeviceManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DeviceManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1GetDevice(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetDevice(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1GetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetDevices(limit, offset, deviceIds, q, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1PostDevice(
      saveDeviceParams: SaveDeviceParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1PostDevice(saveDeviceParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1PutDevice(
      saveDeviceParams: SaveDeviceParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutDevice(saveDeviceParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1PutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutDeviceOtp(updateDeviceOTPRequestParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Export users or devices to CSV file
     * @summary Export users or devices
     * @param {'user' | 'device'} entity Export list of all users or devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2Export(
      entity: "user" | "device",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2Export(entity, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2GetDevice(
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetDevice(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2GetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetDevices(limit, offset, deviceIds, q, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API to update device state (active/inactive)
     * @summary Patch device
     * @param {string} id Device id to patch/update
     * @param {PatchDeviceRequest} patchDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PatchDevice(
      id: string,
      patchDeviceRequest: PatchDeviceRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PatchDevice(id, patchDeviceRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PostDevice(
      saveDeviceParams: SaveDeviceParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PostDevice(saveDeviceParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PutDevice(
      saveDeviceParams: SaveDeviceParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutDevice(saveDeviceParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutDeviceOtp(updateDeviceOTPRequestParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DeviceManagementApi - factory interface
 * @export
 */
export const DeviceManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DeviceManagementApiFp(configuration);
  return {
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetDevice(id: string, options?: any): AxiosPromise<Device> {
      return localVarFp.v1GetDevice(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Devices> {
      return localVarFp.v1GetDevices(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PostDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
      return localVarFp.v1PostDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
      return localVarFp.v1PutDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: any): AxiosPromise<Otp> {
      return localVarFp
        .v1PutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Export users or devices to CSV file
     * @summary Export users or devices
     * @param {'user' | 'device'} entity Export list of all users or devices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2Export(entity: "user" | "device", options?: any): AxiosPromise<any> {
      return localVarFp.v2Export(entity, options).then((request) => request(axios, basePath));
    },
    /**
     * API to get device details by device id
     * @summary Get device
     * @param {string} id Device id to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetDevice(id: string, options?: any): AxiosPromise<Device> {
      return localVarFp.v2GetDevice(id, options).then((request) => request(axios, basePath));
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Devices> {
      return localVarFp.v2GetDevices(limit, offset, deviceIds, q, options).then((request) => request(axios, basePath));
    },
    /**
     * API to update device state (active/inactive)
     * @summary Patch device
     * @param {string} id Device id to patch/update
     * @param {PatchDeviceRequest} patchDeviceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PatchDevice(id: string, patchDeviceRequest: PatchDeviceRequest, options?: any): AxiosPromise<void> {
      return localVarFp.v2PatchDevice(id, patchDeviceRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Registers a device
     * @summary Register the device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PostDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
      return localVarFp.v2PostDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update a device
     * @param {SaveDeviceParams} saveDeviceParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutDevice(saveDeviceParams: SaveDeviceParams, options?: any): AxiosPromise<void> {
      return localVarFp.v2PutDevice(saveDeviceParams, options).then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: any): AxiosPromise<Otp> {
      return localVarFp
        .v2PutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DeviceManagementApi - object-oriented interface
 * @export
 * @class DeviceManagementApi
 * @extends {BaseAPI}
 */
export class DeviceManagementApi extends BaseAPI {
  /**
   * API to get device details by device id
   * @summary Get device
   * @param {string} id Device id to get details for
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v1GetDevice(id: string, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v1GetDevice(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v1GetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v1GetDevices(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Registers a device
   * @summary Register the device
   * @param {SaveDeviceParams} saveDeviceParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v1PostDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v1PostDevice(saveDeviceParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a device
   * @param {SaveDeviceParams} saveDeviceParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v1PutDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v1PutDevice(saveDeviceParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v1PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v1PutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Export users or devices to CSV file
   * @summary Export users or devices
   * @param {'user' | 'device'} entity Export list of all users or devices
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2Export(entity: "user" | "device", options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2Export(entity, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API to get device details by device id
   * @summary Get device
   * @param {string} id Device id to get details for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2GetDevice(id: string, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2GetDevice(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2GetDevices(limit?: number, offset?: string, deviceIds?: string, q?: string, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2GetDevices(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API to update device state (active/inactive)
   * @summary Patch device
   * @param {string} id Device id to patch/update
   * @param {PatchDeviceRequest} patchDeviceRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2PatchDevice(id: string, patchDeviceRequest: PatchDeviceRequest, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2PatchDevice(id, patchDeviceRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Registers a device
   * @summary Register the device
   * @param {SaveDeviceParams} saveDeviceParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2PostDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2PostDevice(saveDeviceParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update a device
   * @param {SaveDeviceParams} saveDeviceParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2PutDevice(saveDeviceParams: SaveDeviceParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2PutDevice(saveDeviceParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeviceManagementApi
   */
  public v2PutDeviceOtp(updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams, options?: AxiosRequestConfig) {
    return DeviceManagementApiFp(this.configuration)
      .v2PutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SimulatorDeviceManagementApi - axios parameter creator
 * @export
 */
export const SimulatorDeviceManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1SimulatorGetDevices: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/simulator/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1SimulatorPutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v1SimulatorPutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/simulator/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SimulatorGetDevices: async (
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/simulator/device`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (deviceIds !== undefined) {
        localVarQueryParameter["device_ids"] = deviceIds;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SimulatorPutDeviceOtp: async (
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateDeviceOTPRequestParams' is not null or undefined
      assertParamExists("v2SimulatorPutDeviceOtp", "updateDeviceOTPRequestParams", updateDeviceOTPRequestParams);
      const localVarPath = `/v2/simulator/device/otp`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Simulator required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDeviceOTPRequestParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SimulatorDeviceManagementApi - functional programming interface
 * @export
 */
export const SimulatorDeviceManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SimulatorDeviceManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1SimulatorGetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1SimulatorGetDevices(
        limit,
        offset,
        deviceIds,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1SimulatorPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1SimulatorPutDeviceOtp(
        updateDeviceOTPRequestParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SimulatorGetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Devices>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SimulatorGetDevices(
        limit,
        offset,
        deviceIds,
        q,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2SimulatorPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Otp>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2SimulatorPutDeviceOtp(
        updateDeviceOTPRequestParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SimulatorDeviceManagementApi - factory interface
 * @export
 */
export const SimulatorDeviceManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SimulatorDeviceManagementApiFp(configuration);
  return {
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1SimulatorGetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Devices> {
      return localVarFp
        .v1SimulatorGetDevices(limit, offset, deviceIds, q, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1SimulatorPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: any
    ): AxiosPromise<Otp> {
      return localVarFp
        .v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Obtain a paginated list of devices
     * @summary Get list of devices
     * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
     * @param {string} [offset] Key of last item returned in previous request - used for pagination
     * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
     * @param {string} [q] Search term for device-id and device-name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SimulatorGetDevices(
      limit?: number,
      offset?: string,
      deviceIds?: string,
      q?: string,
      options?: any
    ): AxiosPromise<Devices> {
      return localVarFp
        .v2SimulatorGetDevices(limit, offset, deviceIds, q, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * An OTP can only be generated if it has a branch association
     * @summary Regenerate OTP for device
     * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2SimulatorPutDeviceOtp(
      updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
      options?: any
    ): AxiosPromise<Otp> {
      return localVarFp
        .v2SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SimulatorDeviceManagementApi - object-oriented interface
 * @export
 * @class SimulatorDeviceManagementApi
 * @extends {BaseAPI}
 */
export class SimulatorDeviceManagementApi extends BaseAPI {
  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public v1SimulatorGetDevices(
    limit?: number,
    offset?: string,
    deviceIds?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .v1SimulatorGetDevices(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public v1SimulatorPutDeviceOtp(
    updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
    options?: AxiosRequestConfig
  ) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .v1SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain a paginated list of devices
   * @summary Get list of devices
   * @param {number} [limit] Total devices to return per request - default is 30 (maximum - 200)
   * @param {string} [offset] Key of last item returned in previous request - used for pagination
   * @param {string} [deviceIds] Device ID\&#39;s to return, doesn\&#39;t work in tandem with pagination, max 100 records returned
   * @param {string} [q] Search term for device-id and device-name
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public v2SimulatorGetDevices(
    limit?: number,
    offset?: string,
    deviceIds?: string,
    q?: string,
    options?: AxiosRequestConfig
  ) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .v2SimulatorGetDevices(limit, offset, deviceIds, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * An OTP can only be generated if it has a branch association
   * @summary Regenerate OTP for device
   * @param {UpdateDeviceOTPRequestParams} updateDeviceOTPRequestParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorDeviceManagementApi
   */
  public v2SimulatorPutDeviceOtp(
    updateDeviceOTPRequestParams: UpdateDeviceOTPRequestParams,
    options?: AxiosRequestConfig
  ) {
    return SimulatorDeviceManagementApiFp(this.configuration)
      .v2SimulatorPutDeviceOtp(updateDeviceOTPRequestParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserManagementApi - axios parameter creator
 * @export
 */
export const UserManagementApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("v1GetUser", "username", username);
      const localVarPath = `/users/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetUsers: async (
      limit?: number,
      offset?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PostUser: async (createUserParams: CreateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createUserParams' is not null or undefined
      assertParamExists("v1PostUser", "createUserParams", createUserParams);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutUser: async (
      username: string,
      updateUserParams: UpdateUserParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("v1PutUser", "username", username);
      // verify required parameter 'updateUserParams' is not null or undefined
      assertParamExists("v1PutUser", "updateUserParams", updateUserParams);
      const localVarPath = `/users/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("v2GetUser", "username", username);
      const localVarPath = `/v2/users/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetUsers: async (
      limit?: number,
      offset?: string,
      q?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (q !== undefined) {
        localVarQueryParameter["q"] = q;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PostUser: async (createUserParams: CreateUserParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'createUserParams' is not null or undefined
      assertParamExists("v2PostUser", "createUserParams", createUserParams);
      const localVarPath = `/v2/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutUser: async (
      username: string,
      updateUserParams: UpdateUserParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists("v2PutUser", "username", username);
      // verify required parameter 'updateUserParams' is not null or undefined
      assertParamExists("v2PutUser", "updateUserParams", updateUserParams);
      const localVarPath = `/v2/users/{username}`.replace(`{${"username"}}`, encodeURIComponent(String(username)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication DeviceManager required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateUserParams, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserManagementApi - functional programming interface
 * @export
 */
export const UserManagementApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserManagementApiAxiosParamCreator(configuration);
  return {
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1GetUser(
      username: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetUser(username, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1GetUsers(
      limit?: number,
      offset?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1GetUsers(limit, offset, q, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1PostUser(
      createUserParams: CreateUserParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1PostUser(createUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async v1PutUser(
      username: string,
      updateUserParams: UpdateUserParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1PutUser(username, updateUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2GetUser(
      username: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetUser(username, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2GetUsers(
      limit?: number,
      offset?: string,
      q?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Users>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2GetUsers(limit, offset, q, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PostUser(
      createUserParams: CreateUserParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PostUser(createUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v2PutUser(
      username: string,
      updateUserParams: UpdateUserParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v2PutUser(username, updateUserParams, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserManagementApi - factory interface
 * @export
 */
export const UserManagementApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserManagementApiFp(configuration);
  return {
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetUser(username: string, options?: any): AxiosPromise<User> {
      return localVarFp.v1GetUser(username, options).then((request) => request(axios, basePath));
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1GetUsers(limit?: number, offset?: string, q?: string, options?: any): AxiosPromise<Users> {
      return localVarFp.v1GetUsers(limit, offset, q, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PostUser(createUserParams: CreateUserParams, options?: any): AxiosPromise<void> {
      return localVarFp.v1PostUser(createUserParams, options).then((request) => request(axios, basePath));
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    v1PutUser(username: string, updateUserParams: UpdateUserParams, options?: any): AxiosPromise<void> {
      return localVarFp.v1PutUser(username, updateUserParams, options).then((request) => request(axios, basePath));
    },
    /**
     * API to get user details by user id
     * @summary Get user
     * @param {string} username Username to get details for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetUser(username: string, options?: any): AxiosPromise<User> {
      return localVarFp.v2GetUser(username, options).then((request) => request(axios, basePath));
    },
    /**
     * Obtain a paginated list of users
     * @summary Get list of users
     * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
     * @param {string} [offset] offset to retrieve next list of users by pagination
     * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2GetUsers(limit?: number, offset?: string, q?: string, options?: any): AxiosPromise<Users> {
      return localVarFp.v2GetUsers(limit, offset, q, options).then((request) => request(axios, basePath));
    },
    /**
     * Create a new user with provided details
     * @summary Add new user
     * @param {CreateUserParams} createUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PostUser(createUserParams: CreateUserParams, options?: any): AxiosPromise<void> {
      return localVarFp.v2PostUser(createUserParams, options).then((request) => request(axios, basePath));
    },
    /**
     * API to update user details
     * @summary Update user details
     * @param {string} username Username to update details for
     * @param {UpdateUserParams} updateUserParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v2PutUser(username: string, updateUserParams: UpdateUserParams, options?: any): AxiosPromise<void> {
      return localVarFp.v2PutUser(username, updateUserParams, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserManagementApi - object-oriented interface
 * @export
 * @class UserManagementApi
 * @extends {BaseAPI}
 */
export class UserManagementApi extends BaseAPI {
  /**
   * API to get user details by user id
   * @summary Get user
   * @param {string} username Username to get details for
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v1GetUser(username: string, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v1GetUser(username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain a paginated list of users
   * @summary Get list of users
   * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
   * @param {string} [offset] offset to retrieve next list of users by pagination
   * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v1GetUsers(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v1GetUsers(limit, offset, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new user with provided details
   * @summary Add new user
   * @param {CreateUserParams} createUserParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v1PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v1PostUser(createUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API to update user details
   * @summary Update user details
   * @param {string} username Username to update details for
   * @param {UpdateUserParams} updateUserParams
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v1PutUser(username: string, updateUserParams: UpdateUserParams, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v1PutUser(username, updateUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API to get user details by user id
   * @summary Get user
   * @param {string} username Username to get details for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v2GetUser(username: string, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v2GetUser(username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Obtain a paginated list of users
   * @summary Get list of users
   * @param {number} [limit] Total Users to return per request - default is 30 (maximum - 60)
   * @param {string} [offset] offset to retrieve next list of users by pagination
   * @param {string} [q] Search user by email (case-insensitive, minimum 3 characters)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v2GetUsers(limit?: number, offset?: string, q?: string, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v2GetUsers(limit, offset, q, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new user with provided details
   * @summary Add new user
   * @param {CreateUserParams} createUserParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v2PostUser(createUserParams: CreateUserParams, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v2PostUser(createUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * API to update user details
   * @summary Update user details
   * @param {string} username Username to update details for
   * @param {UpdateUserParams} updateUserParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserManagementApi
   */
  public v2PutUser(username: string, updateUserParams: UpdateUserParams, options?: AxiosRequestConfig) {
    return UserManagementApiFp(this.configuration)
      .v2PutUser(username, updateUserParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
